import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Outlet, useOutletContext, useMatch } from 'react-router-dom'
import { Row, Col, Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getRyderCupScoreboard, getMatchesScoreboard, getSkinsAndProxiesScoreboard, 
    getMedalistScoreboard } from '@severed-links/common.severedlinks-reducers/groups'
import RyderCupRoundScoreboard from './RyderCupRoundScoreboard'
import TripMedalistScoreboard from './TripMedalistScoreboard'
import TripRoundScoreboard from './TripRoundScoreboard'
import moment from 'moment-timezone'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import { orderBy } from 'lodash'
import * as s from './TripScoreboard.scss'

const TripScoreboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const match = useMatch(`/group/:groupId/trip/:tripId/scoreboard/:tripSubTab`)
    const matchRound = useMatch(`/group/:groupId/trip/:tripId/scoreboard/:tripSubTab/:round/*`)
    const _tripContext = useOutletContext()
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    if (!groupId || !tripId) return null 
    const params = (matchRound || match || {}).params || {}
    const tripSubTab = params.tripSubTab || null
    const round = parseInt(params.round || '0')
    const path = !isGlobalAdminPath ? `/group/:groupId/` : `/global-admin/groups/:globalAdminLetter/view/:groupId/`
    const rounds = !!trip.rounds && Array.isArray(trip.rounds) ? trip.rounds : []
    const roundStartTimes = orderBy(rounds, ['teeTimeDate','round'], ['desc','desc']).map(i => ({ round: i.round, startTime: moment(i.teeTimeDate).toISOString(true) }))
    const _currentRound = roundStartTimes.find(i => moment().isAfter(moment(i.startTime)))

    useEffect(() => {
        loadAllScoreboards()
    }, [])

    useEffect(() => {
        if (!tripSubTab && rounds.length) {
            // TODO: Work on routing to round-1... etc if during trip based on tee times
            const path = routingPath(groupId, isGlobalAdminPath, group.name.substring(0,1).toUpperCase())
            if (trip.hasMedalistScoreboard && (trip.isBefore || trip.isAfter)) {
                navigate(`${path}trip/${tripId}/scoreboard/medalist`, { replace: true })
            } else if (trip.isDuring && _currentRound) {
                navigate(`${path}trip/${tripId}/scoreboard/round/${_currentRound.round}`, { replace: true })
            } else {
                navigate(`${path}trip/${tripId}/scoreboard/round/1`, { replace: true })
            }
        }
    }, [tripSubTab, rounds.length])

    const loadAllScoreboards = () => {
        if (groupId && tripId) {
            dispatch(getRyderCupScoreboard(groupId, tripId))
            dispatch(getSkinsAndProxiesScoreboard(groupId, tripId))
            dispatch(getMedalistScoreboard(groupId, tripId))
            dispatch(getMatchesScoreboard(groupId, tripId))
        }
    }

    const handleSelect = e => {
        const path = routingPath(groupId, isGlobalAdminPath, (group.name || {}).substring(0,1).toUpperCase())
        navigate(`${path}trip/${tripId}/scoreboard/${e}`)
    }

    if (!trip.scoreboard) return null
    return (
        <div className={s.container}>

            <h3>Scoreboard</h3>

            {trip.hasRyderScoreboard ?
            <div className={s.ryderContainer}>
                <RyderCupRoundScoreboard {...(trip || {})} 
                    roundScores={(trip.scoreboard || {}).ryderCup}
                    ryderCupRounds={(trip.scoreboard || {}).ryderCupRounds || []}
                    numRounds={(trip.rounds || []).length} 
                    showRounds />
            </div>
            : null}

            <div className={s.navContainer}>
                <Nav className={s.nav} variant='pills' activeKey={tripSubTab} onSelect={e => handleSelect(e)}>
                    {trip.hasMedalistScoreboard ? <Nav.Item className={s.item}><Nav.Link className={s.link + (tripSubTab === `medalist` ? ` ${s.active}` : ``)} eventKey={'medalist'}><FontAwesomeIcon name='trophy' /> <span className='d-none d-md-inline-block'>Medalist Standings</span></Nav.Link></Nav.Item> : null}
                    {rounds && rounds.map(r =>
                        <Nav.Item className={s.item} key={`trip-scoreboard-${tripId}-${r.round}`}>
                            <Nav.Link className={s.link + (tripSubTab === 'round' && r.round === round ? ` ${s.active}` : ``)} eventKey={`round/${r.round}`}>
                                <span className='d-none d-md-inline-block'><FontAwesomeIcon name='list' /> {r.roundName}</span>
                                <span className='d-block d-md-none' style={{ whiteSpace: 'nowrap' }}><FontAwesomeIcon name='list' /> {r.round}</span>
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>
            </div>

            <Outlet context={_tripContext} />
        </div>
    )
}

export default TripScoreboard